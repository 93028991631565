import * as React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { PluginStore } from 'graylog-web-plugin/plugin';
import { useRef, useMemo } from 'react';
import styled from 'styled-components';

import ColorMapper from 'views/components/visualizations/ColorMapper';
import Icon from 'components/common/Icon';
import searchTypeDefinition from 'views/logic/SearchType';
import ChartColorContext from 'views/components/visualizations/ChartColorContext';
import defaultWidgetTitle from 'views/components/defaultTitle';
import ReportingWidgetVisualization from 'report/common/ReportingWidgetVisualization';
import type { BackendReportWidget, AvailableWidgetPreview, WidgetValueSummary } from 'report/types';
import type { WidgetExport } from 'views/types';
import { isReportBackendWidget } from 'report/typeGuards/reportingWidget';
import PluggableStoreProvider from 'components/PluggableStoreProvider';
import View from 'views/logic/views/View';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';

import Caption from './Caption';
import Heading from './Heading';
import WidgetDescription, { FallbackWidgetDescription } from './WidgetDescription';
import ErrorBoundary from './ErrorBoundary';

export const Container = styled.div`
  font-size: 90%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media print {
    padding: 0;
    page-break-inside: avoid;
  }
`;

export const VisualizationContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  .quickvalues-table {
    margin: 0 auto;
    text-align: left;
    width: 80%;
  }

  .dc-chart {
    float: none;
  }

  @media print {
    /* This is the desired behaviour, but currently unimplemented in Chrome. It may be implemented in a future version, so we leave it here. */
    page-break-after: avoid;
    page-break-before: avoid;
    break-after: avoid;
    break-before: avoid;
    margin-bottom: 4rem;
  }
`;

const DragHandleIcon = styled(Icon)`
  font-size: 1.2em;
  position: absolute;
  left: 20px;
  top: 25px;

  @media print {
    display: none;
  }
`;

const _searchTypePlugin = (type: string) => {
  const typeDefinition = searchTypeDefinition(type);

  return typeDefinition && typeDefinition.handler
    ? searchTypeDefinition(type).handler
    : {
      convert: (result) => {
        // eslint-disable-next-line no-console
        console.error(`No search type handler for type '${type}' result:`, result);

        return result;
      },
    };
};

type Props = {
  widget: BackendReportWidget | AvailableWidgetPreview,
  value: WidgetValueSummary,
  showHeading?: boolean,
  showCaption?: boolean,
  showHandle?: boolean,
  height: number,
  width: number,
  interactive: boolean,
  limitHeight: boolean,
  hideDescription: boolean,
  hideQuery: boolean,
}

const executionState = SearchExecutionState.empty();

const ReportingWidget = ({
  widget,
  value,
  showCaption,
  showHeading,
  showHandle,
  height,
  width,
  interactive,
  limitHeight,
  hideDescription,
  hideQuery,
}: Props) => {
  const { calculatedAt, hasError, errorMessage, types } = value;
  const heading = useRef(null);
  const caption = useRef(null);
  const isBackendWidget = isReportBackendWidget(widget);
  const widgetId = isBackendWidget ? widget.dashboard_widget_id : widget.id;

  const Description = isBackendWidget && !hideDescription ? (
    <ErrorBoundary FallbackComponent={FallbackWidgetDescription}>
      <WidgetDescription calculatedAt={calculatedAt}
                         widgetConfig={widget.config}
                         widgetTimerange={widget.timerange}
                         widgetQuery={widget.query}
                         widgetType={widget.type}
                         widgetStreams={widget.streams}
                         widgetFilters={widget.filters}
                         hideQuery={hideQuery} />
    </ErrorBoundary>
  ) : null;

  const chartColors = widget?.config?.formattingSettings?.chartColors ?? {};
  const colorMap = ColorMapper.create(Immutable.Map(chartColors));
  const chartColorContext = useMemo(() => ({ colors: colorMap, setColor: () => Promise.resolve() }), [colorMap]);
  const header = (isBackendWidget ? widget.description : widget.title) ?? defaultWidgetTitle(widget);
  const view = useMemo(() => View.create(), []);
  const mappedResult = useMemo(() => {
    const mappedResults = value.result.map((searchType) => _searchTypePlugin(searchType.type).convert(searchType));
    const widgetPlugin: WidgetExport = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());
    const { searchResultTransformer = (x) => x } = widgetPlugin || {};

    return searchResultTransformer(mappedResults);
  }, [value?.result, widget?.type]);

  return (
    <PluggableStoreProvider view={view} isNew={false} executionState={executionState} initialQuery="reporting-query">
      <ChartColorContext.Provider value={chartColorContext}>
        <Container>
          {showHandle && <DragHandleIcon name="sort" />}
          {showHeading && <Heading title={header} ref={heading} />}
          <VisualizationContainer>
            <ReportingWidgetVisualization widget={widget}
                                          widgetId={widgetId}
                                          result={mappedResult}
                                          hasError={hasError}
                                          errorMessage={errorMessage}
                                          types={types}
                                          captionHeight={caption.current?.scrollHeight ?? 0}
                                          headingHeight={heading.current?.scrollHeight ?? 0}
                                          height={height}
                                          width={width}
                                          interactive={interactive}
                                          limitHeight={limitHeight} />
          </VisualizationContainer>
          {(showCaption && !!Description) && <Caption text={Description} ref={caption} />}
        </Container>
      </ChartColorContext.Provider>
    </PluggableStoreProvider>
  );
};

ReportingWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  showHeading: PropTypes.bool,
  showCaption: PropTypes.bool,
  showHandle: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  interactive: PropTypes.bool,
  limitHeight: PropTypes.bool,
};

ReportingWidget.defaultProps = {
  showHeading: true,
  showCaption: true,
  showHandle: true,
  interactive: true,
  limitHeight: false,
};

export default ReportingWidget;
