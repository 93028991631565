import * as React from 'react';
import { useQuery } from '@tanstack/react-query';

import Spinner from 'components/common/Spinner';
import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type GlobalOverride from 'views/logic/search/GlobalOverride';
import ReportingWidget from 'report/common/ReportingWidget';
import type { ParameterValues, WidgetValueSummary } from 'report/types';

type Props = Pick<React.ComponentProps<typeof ReportingWidget>, 'widget'
  | 'interactive'
  | 'showCaption'
  | 'hideDescription'
  | 'hideQuery'
  | 'showHeading'
  | 'showHandle'
  | 'limitHeight'
  | 'width'
  | 'height' > & {
  parameterValues: ParameterValues,
  dashboardId: string,
  widgetId: string,
};

const sourceUrl = '/plugins/org.graylog.plugins.report';

const fetchReportingWidgetValue = (
  dashboardId: string,
  widgetId: string,
  parameterBindings: { [key: string]: ParameterBinding },
  globalOverride: GlobalOverride | undefined | {} = {},
): Promise<WidgetValueSummary> => {
  const request = {
    parameter_bindings: Object.entries(parameterBindings)
      .map(([key, value]): [string, ParameterBinding] => [key, ParameterBinding.create('value', value)])
      .reduce((prev, [key, binding]) => ({ ...prev, [key]: binding }), {}),
    global_override: globalOverride,
  };

  return fetch('POST', URLUtils.qualifyUrl(`${sourceUrl}/widgets/${dashboardId}/${widgetId}/value`), request);
};

const ReportingWidgetPreview = ({ dashboardId, widgetId, parameterValues, widget, height, width, ...rest }: Props) => {
  const { data: value, isLoading, isError, error } = useQuery(['report.widget.preview', dashboardId, widgetId], () => fetchReportingWidgetValue(dashboardId, widgetId, parameterValues));

  if (isError) {
    return <span><strong>Error while fetching widget:</strong> <p>{JSON.stringify(error, null, 2)}</p></span>;
  }

  return isLoading ? <Spinner /> : <ReportingWidget {...rest} widget={widget} value={value} height={height} width={width} />;
};

export default ReportingWidgetPreview;
